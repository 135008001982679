<template>
  <div class="d-flex flex-column flex-grow-1 overflow-y-hidden">
    <document-toolbar
      :tab="tab"
      v-on="$listeners"
    >
      <template v-slot:prepend>
        <div
          class="d-flex align-center"
          v-if="reminder"
        >
          <two-state-btn-toggle
            v-if="canUpdate && !previewMode"
            :items="computedItems"
            :value.sync="statusValue"
            @update:value="navigateToDunningReminder()"
            enable-tooltip
          />
          <span v-else>{{reminder.status.text}}</span>
          <v-btn
            v-if="reminder.messageId"
            class="ml-2"
            @click="openMessage"
          >
            <v-icon>{{$icon('i.Eye')}}</v-icon>
            <span class="ml-1">{{$t('t.Email')}}</span>
          </v-btn>
          <v-btn
            v-if="reminder.reminderType.value === 'letter' && reminder.status.value === 'sent' && !previewMode"
            class="ml-2"
            @click="downloadPDFFile"
          >
            <v-icon>{{$icon('i.Download')}}</v-icon>
            <span class="ml-1">{{$t('t.DownloadPDF')}}</span>
          </v-btn>
        </div>
      </template>
    </document-toolbar>
    <document-in-tab class="d-flex">
      <div
        v-if="reminder"
        class="main-content d-flex flex-column flex-grow-1"
        ref="reminder"
      >
        <reminder-header :reminder="reminder" />
        <v-card class="d-flex flex-column py-4 px-0 mx-1 mb-2 overflow-y-hidden">
          <div class="overflow-y-auto px-4">
            <span class="mb-4">{{reminder.subject}}</span>
            <span
              class="mb-4"
              v-html="reminder.header"
            />
            <span
              class="mb-4"
              v-html="reminder.content"
            />
            <span
              class="mb-4"
              v-html="reminder.footer"
            />
          </div>
        </v-card>
      </div>
    </document-in-tab>
  </div>
</template>

<script>
import Search from '@/pages/search/controllers'
import GlobalSearch from '@/pages/search/controllers/global-search'
import tabController from '@/tabController'
import recentsController from '@/recentsController'
import fileDownload from 'js-file-download'

export default {
  components: {
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    ReminderHeader: () => import('@/components/reminder-header'),
    TwoStateBtnToggle: () => import('@/components/two-state-btn-toggle')
  },
  async activated () {
    this.init()
  },
  deactivated () {
    this.search = undefined
  },
  async mounted () {
    this.init()
  },
  data () {
    return {
      isManualValidationMode: false,
      reminder: null,
      search: null
    }
  },
  computed: {
    isDunningReminder () {
      return this.reminderType === 'dunning-reminders'
    },
    isCalendarBasedReminder () {
      return this.reminderType === 'calendar-based-reminders'
    },
    canUpdate () {
      return this.search && !(this.reminder.status.value === 'sent' || this.reminder.status.value === 'rejected')
    },
    computedItems () {
      if (this.isDunningReminder) {
        return [{ label: this.btn0.label, value: 'proposed', tooltip: this.btn0.tooltip }, { label: this.btn1.label, value: 'neutral', tooltip: this.btn1.tooltip }, { label: this.btn2.label, value: 'excluded', tooltip: this.btn2.tooltip }]
      } else {
        return [{ label: this.btn0.label, value: 'proposed', tooltip: this.btn0.tooltip }, { label: this.btn2.label, value: 'excluded', tooltip: this.btn2.tooltip }]
      }
    },
    statusValue: {
      get () {
        if (!this.reminder) {
          return
        }
        return this.reminder.status.value
      },
      set (v) {
        if (!this.reminder && !this.search) {
          return
        }
        this.processState(v)
      }
    },
    btn0 () {
      return {
        label: this.$t('t.Include'),
        target: 'proposed',
        tooltip: this.$t('t.IncludeTooltip')
      }
    },
    btn1 () {
      return {
        label: this.isManualValidationMode ? this.$t('t.ToValidate') : this.$t('t.Proposed'),
        target: 'neutral',
        tooltip: this.isManualValidationMode ? this.$t('t.ToValidateTooltip') : this.$t('t.ProposedTooltip')
      }
    },
    btn2 () {
      return {
        label: this.$t('t.Exclude'),
        target: 'excluded',
        tooltip: this.$t('t.ExcludeTooltip')
      }
    }
  },
  methods: {
    async init () {
      const url = this.isDunningReminder ? 'escalation-protocol' : 'calendar-based-reminder'
      this.isManualValidationMode = (await this.$http().get(`/core/v6/settings/${url}`)).data.manualValidationMode
      await this.loadReminder()
      this.createSearch()
    },
    createSearch () {
      this.search = new Search(Search.actions.SET_REMINDER_STATUS)
        .chain(s => s.searchedDocumentTypes.include([this.reminderType]))
      this.search.searchedIds.clear().include([{ id: this.reminder.id, type: this.reminderType }])
    },
    openMessage () {
      recentsController.addTab(tabController.create(tabController.messagesOutboxTab(), { documentId: this.reminder.messageId }))
    },
    async downloadPDFFile () {
      const url = this.isDunningReminder ? 'dunning-reminders' : 'calendar-based-reminders'
      const r = await this.$http().get(`/core/v6/${url}/${this.reminder.id}/letter`, { responseType: 'blob' })

      let filename = ''
      const disposition = r.headers['content-disposition']

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches !== null && matches[1]) {
          filename = matches[1].replace(/['"/]/g, '')
        }
      }

      filename = this.removeDiacritics(filename).replace(/\//g, '')
      fileDownload(r.data, filename)
    },
    setTabTitle (title) {
      const t = !title ? this.tab.typeName : title
      this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${t}` })
    },
    async loadReminder () {
      this.$emit('document-reset', this.tab)
      const url = this.isDunningReminder ? 'dunning-reminders' : 'calendar-based-reminders'

      const result = await this.$http().get(`/core/v6/${url}/${this.tab.documentId}`)
      this.reminder = result.data
      this.setTabTitle(this.reminder.number)
      this.$nextTick(() => this.$refs?.reminder?.querySelectorAll('script, style').forEach(el => el.remove()))
    },
    async processState (target) {
      this.search.executeWithJSON({ target })
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Success'))
        }).catch(e => {
          this.$store.dispatch('showErrorSnackbar', e.message)
        })
    },
    navigateToDunningReminder () {
      if (this.isDunningReminder) {
        this.$router.push('/search').then(() => GlobalSearch?.activeSearch?.setDocumentType('dunning-reminders').execute(true))
      }
    }
  },
  props: {
    tab: Object,
    reminderType: String,
    previewMode: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus" scoped>
.main-content, .v-skeleton-loader
  margin 0.5em
  overflow auto

.borderless
  border none
</style>
